import * as React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql } from "gatsby"
import { css } from "linaria"

import { blogWidth } from "../consts"
import { Layout } from "../components/layout"
import { BlogPosts } from "../components/blog-posts"

const cn = {
  container: css``,
  title: css`
    text-align: center;
    font-family: 'Righteous', cursive;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    margin-top: 0.5rem;
  `,
  content: css`
    display: flex;
    justify-content: center;
    margin: 0 auto 2.2rem;
  `,
  blogWrapper: css`
    max-width: var(--video-width);
    margin: 0 auto;
  `
}

const Index = ({ data, location }) => {
  const posts = data.posts.nodes
  const mains = data.main.nodes

  return (
    <Layout location={location} title={""}>
      {
        mains.map(main => (
          <div className={cn.container} key={`main-${main.slug}`}>
            {
              main.frontmatter.title ? <h1 className={cn.title}>{main.frontmatter.title}</h1> : null
            }
            <div className={cn.content}>
              <MDXRenderer>{main.body}</MDXRenderer>
            </div>
          </div>
        ))
      }
      {
        posts.length > 0
          ? (
            <div className={cn.blogWrapper}>
              <BlogPosts posts={posts} />
            </div>
          )
          : null
      }
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {section: {eq: "blog"}}}
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          title
          description
        }
      }
    }
    main: allMdx(sort: {order: ASC, fields: slug}) {
      nodes {
        body
        slug
        frontmatter {
          title
        }
      }
    }
  }

`
